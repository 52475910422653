import moment from 'moment';

moment.locale('zh-cn');
function getDateTime(date, type = 'YYYY-MM-DD HH:mm:ss') {
  return moment(date)
    .format(type)
    .toString();
}

function getDate(date, type = 'YYYY-MM-DD') {
  return moment(date)
    .format(type)
    .toString();
}

export {
  getDate,
  getDateTime,
};
export default moment;
